import React from 'react';
import { Heading } from '@autovia-uk/polaris-components/components/molecules/Heading';
import injectionEngine from '@carwow/injection-engine';
import rules from '@carwow/injection-default-rules-set';
import { getArticleGroupContent } from '@autovia-uk/polaris-components/sharedHelpers/getArticleGroupContent';
import { getArticleBody } from '@autovia-uk/polaris-components/sharedPartials/getArticleBody';
import { getArticleGroup } from '@autovia-uk/polaris-components/sharedPartials/getArticleGroup';
import { getBreadcrumbs } from '@autovia-uk/polaris-components/sharedPartials/getBreadcrumbs';

import { getMostPopularArticleGroup } from '@autovia-uk/polaris-components/sharedHelpers/getMostPopularArticleGroup';

import { basicPagePropsShape } from './props-shape';
import 'Styles/components/templates/_BasicPage.scss';

export const BasicPage = (props) => {
  const {
    config: {
      breakpoints,
      globalSettings: {
        newsletterLink,
        promoBoxSettings,
        adSettings,
      },
      newsletter: newsletterSettings,
    },
    layoutData: {
      page: {
        associatedContent,
        body,
        breadcrumbs,
        isSponsored,
        kicker,
        metaData,
        monetising,
        subtitle,
        title,
      },
    },
  } = props;

  const promoBox = { ...promoBoxSettings, extraClassNames: { '-inline': true } };
  const newsletter = {
    props: {},
    portalNewsletterUrl: newsletterLink,
    configNewsletter: newsletterSettings,
    additionalProps: { extraClassNames: { '-inline': true } },
  };

  const bodyInjected = injectionEngine({
    body,
    props: {
      adSettings,
      breakpoints,
      metaData,
      monetising,
      newsletterConfig: newsletter,
      promoBoxSettings: promoBox,
    },
    rules,
  });

  const ArticleBody = getArticleBody({
    bodyInjected,
    props,
    template: 'Basic',
  });

  return (
    <div className="polaris__body">
      <main className="polaris__main" id="main">

        <div className="polaris__core-content polaris__content">

          {getBreadcrumbs({
            breadcrumbs, isSponsored, kicker, extraClassNames: { '-kicker-only': true },
          })}

          <Heading size={1} extraClassNames={{ '-title': true }}>
            {title}
          </Heading>

          <Heading size={2} extraClassNames={{ '-subtitle': true }}>
            {subtitle}
          </Heading>

        </div>

        { ArticleBody }

        <div className="polaris__core-content polaris__content">
          {getArticleGroup({
            title: 'Recommended',
            content: getArticleGroupContent({ related: associatedContent, key: 'recommended' }),
            articlesPerRow: 4,
            articleCardProps: {
              rating: 4,
              showRating: true,
              datePosition: 'bottom',
            },
          })}

          {getMostPopularArticleGroup({
            articleCardProps: {
              datePosition: 'bottom',
              isHeading: false,
              showRating: true,
            },
            associatedContent,
            nativeAd: 'native_article',
          })}
        </div>
      </main>
    </div>
  );
};

BasicPage.propTypes = {
  ...basicPagePropsShape,
};
