import get from 'lodash.get';
import { Helmet, imageSizesCallback } from 'polaris-coreweb/exports';
import { getHeaderConfig } from './getHeaderConfig';
import { getFooterConfig } from './getFooterConfig';

export const contextOverride = (config, adConfig, type) => ({
  adConfig,
  branding: get(config, 'branding', {}),
  breakpoints: get(config, 'breakpoints', {}),
  dianomi: get(config, 'dianomi', {}),
  footer: getFooterConfig(config),
  globalSettings: get(config, 'globalSettings', {}),
  header: getHeaderConfig(config),
  HelmetComponent: Helmet,
  imageSizesCallback: imageSizesCallback(config),
  makeModelData: get(config, 'globalSettings.productData', []),
  newsletterURL: get(config, 'newsletter.newsletterURL', ''),
  newsletter: get(config, 'newsletter', {}),
  platform: 'canonical',
  ratingConfig: get(config, 'rating.icon', {}),
  ratingFillColor: get(config, 'rating.color.ratingFillColor', {}),
  ratingFillInactiveColor: get(config, 'rating.color.ratingFillInactiveColor', {}),
  social: get(config, 'social', {}),
  svgSpritePath: get(config, 'svgSpritePath', '/assets/polaris-sprite.svg'),
  testing: true,
  type,
});
