import React from 'react';
import classNames from 'classnames';
import { Heading } from '@autovia-uk/polaris-components/components/molecules/Heading';
import { ShortAuthorBio } from '@autovia-uk/polaris-components/components/molecules/ShortAuthorBio';
import injectionEngine from '@carwow/injection-engine';
import rules from '@carwow/injection-default-rules-set';
import { HtmlSafe } from 'polaris-coreweb/exports';
import { getArticleGroup } from '@autovia-uk/polaris-components/sharedPartials/getArticleGroup';
import { getBody } from '@autovia-uk/polaris-components/sharedPartials/getBody';
import { getBreadcrumbs } from '@autovia-uk/polaris-components/sharedPartials/getBreadcrumbs';
import { getIndexArticlePageHeader } from '@autovia-uk/polaris-components/sharedPartials/getIndexArticlePageHeader';
import { getIndexArticlePageContent } from '@autovia-uk/polaris-components/sharedPartials/getIndexArticlePageContent';
import { getNextPagesForArticleAndReview } from 'SharedPartialsLocal/getNextPagesForArticleAndReview';
import { getNextSteps } from 'SharedPartialsLocal/getNextSteps';
import { getPostMeta } from 'SharedPartialsLocal/getPostMeta';
import {
  getReportCard,
  getReviewProCon,
  getRelatedContent,
  getNextPagination,
} from 'SharedPartialsLocal';
import { getReviewMultipageNavigation } from '@autovia-uk/polaris-components/sharedPartials/getReviewMultipageNavigation';
import { getPrimaryMedia } from 'SharedPartialsLocal/getPrimaryMedia';
import { getSocial } from 'SharedPartialsLocal/getSocial';
import { getSponsorInfo } from '@autovia-uk/polaris-components/sharedPartials/getSponsorInfo';
import { getTags } from '@autovia-uk/polaris-components/sharedPartials/getTags';
import { getAfterContentAd } from 'SharedPartialsLocal/getAfterContentAd';
import { countBodyInjectedInlineAds } from '@autovia-uk/polaris-components/sharedHelpers/countBodyInjectedInlineAds';
import { getArticleGroupContent } from '@autovia-uk/polaris-components/sharedHelpers/getArticleGroupContent';
import { getIndexArticlePageList } from '@autovia-uk/polaris-components/sharedHelpers/getIndexArticlePageList';
import { getMostPopularArticleGroup } from '@autovia-uk/polaris-components/sharedHelpers/getMostPopularArticleGroup';
import { useViewportType } from '@autovia-uk/polaris-components/components/protons/ViewportChecker';

import { reviewPropsShape } from './props-shape';

import 'Styles/components/templates/_Review.scss';

const Review = (props) => {
  const {
    config: {
      newsletter: newsletterSettings,
      breakpoints,
      globalSettings: {
        newsletterLink,
        promoBoxSettings,
        adSettings,
        adSettings: {
          afterContentDesktopAds,
          afterContentMobileAds,
        },
      },
      siteUrl,
    },
    layoutData: {
      page: {
        authors,
        title,
        url,
        subtitle,
        breadcrumbs,
        kicker,
        isSponsored,
        body,
        metaData,
        monetising,
        associatedContent,
        sponsor,
        filters,
        showFilters,
        tags,
        primaryMedia,
        rating,
      },
      page,
    },
    pagination: {
      page: currentPage,
      pages,
    },
  } = props;

  const { isMobile } = useViewportType({
    breakpointsMobile: breakpoints.mobile,
    breakpointsDesktop: breakpoints.desktop,
  });

  const indexData = getIndexArticlePageList(associatedContent);
  const isIndexArticlePage = indexData !== false;
  const multipage = getRelatedContent({ related: associatedContent, key: 'relatedPages' });
  const isMultipage = Array.isArray(multipage) && multipage.length > 0;

  const promoBox = { ...promoBoxSettings, extraClassNames: { '-inline': true } };
  const newsletter = {
    props: {},
    portalNewsletterUrl: newsletterLink,
    configNewsletter: newsletterSettings,
    additionalProps: { extraClassNames: { '-inline': true } },
  };

  let bodyInjected = injectionEngine({
    body,
    props: {
      adSettings,
      breakpoints,
      metaData,
      monetising,
      newsletterConfig: newsletter,
      pageContentType: 'review',
      promoBoxSettings: promoBox,
    },
    rules,
  });

  // replace SUBSCRIBE_NEWSLETTER with SHOPWINDOW block
  bodyInjected = bodyInjected && bodyInjected.map((item) => {
    if (item.type === 'SUBSCRIBE_NEWSLETTER') {
      return {
        ...item,
        ...{ type: 'SHOPWINDOW' },
      };
    }
    return { ...item };
  });

  const countInlineAds = countBodyInjectedInlineAds({
    bodyInjected,
    bodyBlockInlineAdDesktop: 'DESKTOP_INLINE_ADVERT',
    bodyBlockInlineAdMobile: 'MOBILE_INLINE_ADVERT',
  });

  const reportCard = getReportCard({ props });
  const reviewProCon = getReviewProCon(props);
  const toHideReportCard = !rating || rating === 0;

  const taxonomyFilters = Array.isArray(filters) ? filters.map(f => ({
    label: f.title,
    value: f.url,
  })) : null;

  const taxonomyFilterParams = {
    content: taxonomyFilters,
    currentPageUrl: url,
    label: 'Show me:',
    listModeOptions: {
      seeMoreLabel: 'See all',
      seeLessLabel: 'See less',
      icon: 'down-chevron',
    },
  };

  const nextStepsComponent = getNextSteps({ props: page, siteUrl });

  const isAfterContentAds = afterContentDesktopAds || afterContentMobileAds;

  const shortAuthorBio = () => {
    if (!authors || !authors.length) return null;

    const author = authors[0];

    return (
      <ShortAuthorBio
        authorBio={<HtmlSafe html={author.shortBio || ''} />}
        authorLink={author.url}
        authorImage={author.image}
        authorName={author.name}
        authorRole={author.jobTitle}
      />
    );
  };

  const topComponents = toHideReportCard && (
  <div className="polaris__simple-grid--main">
    {getPrimaryMedia(primaryMedia, '-review')}
  </div>

  );

  return (
    <>
      <div className="polaris__body">
        <main className="polaris__main" id="main">
          <div className="polaris__core-content polaris__content">

            { getBreadcrumbs({ breadcrumbs, isSponsored, kicker }) }

            <Heading size={1} extraClassNames={{ '-title': true }}>
              {title}
            </Heading>

            <Heading size={2} extraClassNames={{ '-subtitle': true }}>
              {subtitle}
            </Heading>

            <div className="polaris__simple-grid">
              <div className="polaris__simple-grid--main">
                {getPostMeta(page)}
              </div>
            </div>
          </div>

          <div className="polaris__core-content polaris__content">
            {getReviewMultipageNavigation({
              multipage,
              currentUrl: url,
              nextIcon: 'polaris__ico-right-chevron',
              prevIcon: 'polaris__ico-left-chevron',
            })}
          </div>

          {!toHideReportCard && (
            <div className="polaris__core-content polaris__content -body-copy">
              {reportCard}
            </div>
          )}

          <div className={
            classNames(
              'polaris__content',
              '-body-copy',
              {
                '-no-report-card': toHideReportCard && isMultipage,
              },
            )}
          >
            {getBody({
              bodyInjected: [reviewProCon, getSponsorInfo({ sponsor }), ...bodyInjected],
              template: 'review',
              topComponents,
              isMobile,
            })}

            <div className="polaris__core-content polaris__core-content-last polaris__simple-grid--main">
              {getNextPagination(associatedContent, url)}

              {nextStepsComponent}

              {getNextPagesForArticleAndReview({
                content: multipage,
                currentUrl: url,
                title: 'In This Review',
                showRating: true,
                showSubtitle: true,
              })}

              <div className="-tags-social">
                {getTags({
                  tags,
                  checkTagsCondition: false,
                  extraClassNames: { '-article': true },
                })}
                {getSocial({
                  siteUrl,
                  url,
                  title,
                  extraClassNames: { '-article': true },
                })}
              </div>
              {shortAuthorBio()}
            </div>
          </div>
        </main>
      </div>

      {isAfterContentAds && getAfterContentAd({ afterContentDesktopAds, afterContentMobileAds })}

      <div className="polaris__content polaris__core-content polaris__core-content-first">
        {getArticleGroup({
          title: 'Have You Considered',
          content: getArticleGroupContent({ related: associatedContent, key: 'similarProducts' }),
          articlesPerRow: 4,
          articleCardProps: {
            datePosition: 'bottom',
            excerpt: null,
            showRating: true,
          },
        })}
        {getMostPopularArticleGroup({
          articleCardProps: {
            datePosition: 'bottom',
            isHeading: false,
            showRating: true,
          },
          associatedContent,
          nativeAd: 'native_article',
        })}
      </div>

      {isIndexArticlePage
        && Array.isArray(pages[currentPage])
        && pages[currentPage].length > 0
        && (
          <div className="polaris__core-content polaris__content">
            <div className="polaris__article-group -header">
              {getIndexArticlePageHeader({
                indexData,
                propsData: props,
                showFilters: showFilters && taxonomyFilters !== null && taxonomyFilters.length > 0,
                taxonomyFilterParams,
              })}
            </div>
            {getIndexArticlePageContent(
              indexData,
              {
                ...props,
                articlesPerRow: 4,
                articleCardProps: { datePosition: 'bottom' },
              },
              countInlineAds,
            )}
          </div>
        )}
    </>
  );
};

Review.propTypes = {
  ...reviewPropsShape,
};

Review.defaultProps = {
};

export { Review };
