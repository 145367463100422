import React from 'react';
import { GoTo } from '@autovia-uk/polaris-components/components/molecules/GoTo';

export const getGoTo = ({
  url,
  title,
  gotoLabel = 'Go to',
  gotoBackLabel = 'Go back to',
  icon = 'polaris__ico-next',
}) => (
  <GoTo
    title={title}
    url={url}
    label="Go to"
    icon={icon}
    defaultLabel={gotoLabel}
    defaultIcon={icon}
    direction="prev"
    directions={
      {
        next: {
          label: gotoLabel,
          icon: 'ico-next',
        },
        prev: {
          label: gotoBackLabel,
          icon: 'ico-previous',
        },
      }
    }
  />
);
